// METHODS

export const getFormattedDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
    });
    return formattedDate;
};

export const truncateHTMLString = (htmlString = '', maxLength = 100) => {
    const regex = /(style=".+?")/gm; // remove styling
    const subst = ``;
    htmlString= htmlString.replace(regex, subst).replace( /(<([^>]+)>)/ig, ''); // remove html tags
    if (htmlString.length <= maxLength) {
        return htmlString;
    }

    const truncatedString = htmlString.substring(0, maxLength);
    const lastTagIndex = truncatedString.lastIndexOf('<');
    const lastClosingTagIndex = truncatedString.lastIndexOf('>');

    if (lastTagIndex > lastClosingTagIndex) {
        return truncatedString.slice(0, lastTagIndex);
    }

    return `${truncatedString}...`;
}

// CONSTANTS

export const socialMediaURLs = {
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
    twitter: 'https://twitter.com/intent/tweet?text=',
    email: 'mailto:?body=',
    linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
}