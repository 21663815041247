import React from "react";
import { Container, Image, } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomersignupProcess6 = () => {
    return (
        <div className="customer_signup sec_pd">
            <Container>
                <div className="customer_signup_process">
                    <div className="processTitle black medium fz20 poppins text-center mB20"> Download the app </div>
                    <div className="downloadApp_div w-50 m-auto text-center">
                        <Image className="downloadimg-ic" src="assets/img/download-app.png"></Image>
                        <p className="regular fz18 poppins text-center mB30 d-block d-lg-none">
                            Congratulations! <br></br> You are ready to get protected! <br></br>
                            Download the app to get started
                        </p>
                        <p className="regular fz18 poppins text-center mB30 d-none d-lg-block">
                            Congratulations!  You are ready to get protected! <br></br>
                            Download the app to get started
                        </p>
                        <div className="btnRow">
                            <a href="/#" className="me-2">
                                <img src="assets/img/google-play.svg" alt="icon" />
                            </a>
                            <a href="/#">
                                <img src="assets/img/app-store.svg" alt="icon" />{" "}
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CustomersignupProcess6;
