import React, { Fragment, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { heroBackgroundCheck } from "../api/hero";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const HeroSignupStep5 = () => {

    const navigate = useNavigate();
    const [activeState, setActiveState] = useState('showInstructions');
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true)
    const [pin, setPin] = useState('');

    //normalize input
    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 3) return currentValue;
            if (cvLength < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;
            return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}`;
        }
    };

    const handleChange = (e) => {
        const inputVal = e.target.value;
        setPin(prevState => (normalizeInput(inputVal, prevState)))
        inputVal.length > 10 ? setIsSubmitBtnDisabled(false) : setIsSubmitBtnDisabled(true)

    }

    const handleSubmit = () => {
        try {
            const number = pin.replace(/[^\d]/g, '');
            heroBackgroundCheck({ number }).then(response => {
                if (response.data.success) {
                    setActiveState('showBackgroundCheckResponse')
                }
            })
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <Fragment>

            {activeState === 'showInstructions' && (
                <div className="heroApplication sec_pd">
                    <Container>
                        <div className="uploadyourProfile">
                            <div className="processTitle black medium fz20 poppins text-center mB40">
                                <NavLink className="d-flex align-items-center text-decoration-none text-dark" to="/hero-signup-progress">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M14 18L8 12L14 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> Back
                                </NavLink>
                                Background Check </div>

                            <div className="fz16 regular poppins mT30 mB20 text-center purple d-block"> Check the following explanation  </div>

                            <p className="black poppins fz18 mB20 medium">
                                To access the Hero platform, you must pass a background check.</p>
                            <div>
                                JRTechnologies, LLC. or its affiliates ("Hero") may obtain "customer reports" (background checks) on you through a consumer reporting agency ("CRA") regarding your ability to initiate or maintain a working relationship. autonomous with Hero. These reports may also include information about your criminal history, motor vehicle driving records (which may also include information that a government agency has recorded about your physical health, mental condition or behavior in order to restrict your ability to drive legally) or other background checks.
                            </div>


                            <div className="btnRow w-50 m-auto">
                                <div className="uploadbtn mt-3" onClick={() => { setActiveState('showVerifyPinForm') }}>
                                    Continue
                                </div>
                            </div>

                        </div>
                    </Container>
                </div>
            )}

            {activeState === 'showVerifyPinForm' && (
                <div className="heroApplication sec_pd">
                    <Container>

                        <div className="uploadyourProfile">
                            <div className="processTitle black semibold fz20 poppins text-center mB40">
                                <NavLink className="d-flex align-items-center text-decoration-none text-dark" to="" onClick={() => { setActiveState('showInstructions') }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M14 18L8 12L14 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> Back
                                </NavLink>
                                Social Security Number </div>

                            <div className="text-center mb-5">
                                <input className="stepInput w-50"
                                    type="text"
                                    placeholder="000-00-0000"
                                    onChange={handleChange}
                                    value={pin}
                                />
                            </div>

                            <ul className="m-0 numberList ps-3">
                                <li>  We need your social security number to perform your background check, as well as to process payments to you once you become a working Hero.</li>
                                <li> Your personal information is protected and secure. </li>
                                <li> We will not perform a credit check and the background check will not affect your credit </li>
                            </ul>
                            <NavLink className="fz18 regular poppins mT30 mB20 text-center purple d-block text-decoration-none" onClick={() => { setActiveState('showAdditionalDisclousers') }}>
                                Additional Disclosures - New York/Minnesota
                            </NavLink>
                            <hr></hr>
                            <div className="mt-3">
                                ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND CHECK I acknowledge and agree to have read and understand the background check release. In addition, I acknowledge that I have read, understood, and accepted the statements contained in the additional disclosures. By means of my digital signature I authorize Hero to obtain client reports about me through a consumer reporting agency at any time after receiving this authorization and throughout my relationship as a self-employed worker. I agree that I provide my digital signature and that it is as binding as a paper signature.
                            </div>


                            <div className="btnRow w-50 m-auto mt-3">
                                <Button className="submit-btn mt-3" onClick={handleSubmit} disabled={isSubmitBtnDisabled}>
                                    Accept
                                </Button>
                            </div>

                        </div>
                    </Container >
                </div>
            )}

            {activeState === 'showAdditionalDisclousers' && (
                <div className="heroApplication sec_pd">
                    <Container>
                        <div className="uploadyourProfile">
                            <div className="processTitle black medium fz20 poppins text-center mB40">
                                Additional Disclosures - <br />
                                New York/Minnesota </div>
                            <div>
                                The company conducts consumer reports through Checkr, One Montgomery Street, suite 2000, San Francisco, CA 94104, 1-844-824-3257.<br /><br />

                                New York: You have the right, after submitting a written request, to know whether the Company has requested a background check. above, the address and telephone number of the CRA is indicated.
                                You can review and receive a copy of any of these reports by contacting the CRA.<br /><br />

                                Minnesota: You have the right, upon submitting a written request to the CRA, to a complete and accurate report on the nature and depth of any background checks commissioned by the Company. The CRA will provide you with this report within 5 business days from the day of receipt of your request or the day Hero commissioned the report (the later of the two).
                            </div>


                            <div className="btnRow w-50 m-auto">
                                <div className="uploadbtn mt-3" onClick={() => { setActiveState('showVerifyPinForm') }}>
                                    Back
                                </div>
                            </div>

                        </div>
                    </Container>
                </div>
            )}

            {activeState === 'showBackgroundCheckResponse' && (
                <div className="heroApplication sec_pd">
                    <Container>
                        <div className="uploadyourProfile text-center">
                            <div className="processTitle black semibold fz20 poppins text-center mB40">
                                Background Check </div>

                            <svg width="227" height="227" viewBox="0 0 227 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="113.5" cy="113.5" r="113.5" fill="#00CB45" />
                                <path d="M74.4414 115.395L101.957 143.77L156.987 87.0195" stroke="white" stroke-width="11.7922" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                            <div className="processTitle black semibold fz24 poppins text-center mB10 mT60">Thanks for the info</div>
                            <p className="fz18 poppins black regular mB50">
                                We will begin the background check <br></br> when we receive all of your<br></br> documents.
                            </p>
                            <div className="btnRow w-50 m-auto">
                                <div className="uploadbtn mt-3" onClick={() => { navigate('/hero-signup-progress') }}>
                                    Check my application
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            )}

        </Fragment>

    );
};

export default HeroSignupStep5;
