import axios from "axios";

const base = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en",
    "x-heroapp-platform": "web",
    "x-heroapp-version": "1.0.0",
  },
});

base.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
});

export default base;
