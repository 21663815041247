import React from "react";
import { Button, Col, Container, Form, Image, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomersignupProcess3 = () => {
    return (
        <div className="customer_signup sec_pd">
            <Container>
                <div className="customer_signup_process">
                    <div className="processTitle black medium fz20 poppins text-center mB20"> Track your progress </div>
                    <ProgressBar variant="success" now={60} />
                    <div className="step_container mT20">
                        <div className="step_container_row done d-flex justify-content-between align-items-center">
                            <p className="mb-0"> Submit personal detail </p>
                            <p className="mb-0 rightContent"> Done <Link className="text-decoration-none fz20 medium">Edit</Link> </p>
                        </div>
                        <hr></hr>
                        <div className="step_container_row done d-flex justify-content-between align-items-center">
                            <p className="mb-0"> Submit a profile photo (optional) </p>
                            <p className="mb-0 rightContent"> Submit <Link className="text-decoration-none fz20 medium"> add later </Link> </p>
                        </div>
                        <hr></hr>
                        <div className="step_container_row active d-flex justify-content-between align-items-center">
                            <p className="mb-0"> Add payment method </p>
                            <p className="mb-0 rightContent"> <Link className="text-decoration-none fz20 medium">Add</Link> </p>
                        </div>
                        <hr></hr>
                        <div className="step_container_row d-flex justify-content-between align-items-center">
                            <p className="mb-0"> Download App </p>
                            <p className="mb-0 rightContent"> <Link className="text-decoration-none fz20 medium">Download</Link> </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CustomersignupProcess3;
