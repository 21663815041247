import React from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Image,
    Nav,
    NavItem,
    Row,
    Tab,
} from "react-bootstrap";
const HeroApplication = () => {
    return (
        <div className="heroApplication sec_pd">
            <Container>
                <div>
                    <h4 className="fz20 poppins black semibold text-center mb-4">Hero form</h4>
                    <div className="personalInfo">
                        <p className="blue semibold fz18 poppins"> Personal Information </p>
                        <Row className="mb-4">
                            <Col>
                                <label className="black fz18 regular poppins me-5">Height(ft)</label>
                                <input></input>
                            </Col>
                            <Col>
                                <label className="black fz18 regular poppins me-5">Weight(lb)</label>
                                <input></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="black fz18 regular poppins me-5">Hair Color</label>
                                <input></input>
                            </Col>
                            <Col>
                                <label className="black fz18 regular poppins me-5">Eye Color</label>
                                <input></input>
                            </Col>
                        </Row>
                        <hr className="mt-4 mb-4"></hr>
                    </div>

                    <div className="personalInfo">
                        <p className="blue semibold fz18 poppins"> Personal address </p>
                        <Row className="mb-4 streetRow">
                            <Col>
                                <div className="d-flex align-items-center">
                                    <label className="black fz18 regular poppins me-5"> Street </label>
                                    <input className="w-100"></input>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <label className="black fz18 regular poppins me-5"> Apt </label>
                                <input></input>
                            </Col>
                            <Col>
                                <label className="black fz18 regular poppins me-5">Country</label>
                                <input></input>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <label className="black fz18 regular poppins me-5"> State </label>
                                <input></input>
                            </Col>
                            <Col>
                                <label className="black fz18 regular poppins me-5">City</label>
                                <input></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="black fz18 regular poppins me-5"> Zip Code </label>
                                <input></input>
                            </Col>
                            <Col></Col>
                        </Row>
                        <hr className="mt-4 mb-4"></hr>
                    </div>

                    <div className="personalInfo">
                        <p className="blue semibold fz18 poppins mb-4"> Licenses/Certifications </p>
                        <div className="mb-5">
                            <span className="black medium fz18 poppins">Please state if you have a driver’s license and, if so, in which state.</span>
                            <div className="d-flex justify-content-end statusItem mt-3">
                                <Button className="statusItemdiv">Yes</Button>
                                <Button className="statusItemdiv">No</Button>
                                <Button className="statusItemdiv state">State</Button>
                            </div>
                        </div>
                        <div className="mb-3">
                            <span className="black medium fz18 poppins">
                                The security position is disarmed. Firearms will not be carried to provide security. However, please indicate if you are licensed to carry firearms.
                            </span>
                            <div className="d-flex justify-content-end statusItem mt-3">
                                <Button className="statusItemdiv">Yes</Button>
                                <Button className="statusItemdiv">No</Button>
                                <Button className="statusItemdiv">State</Button>
                            </div>
                        </div>
                        <hr className="mt-4 mb-4"></hr>
                    </div>

                    <div className="personalInfo generalIfo">
                        <p className="blue semibold fz18 poppins mb-4"> General Experience </p>
                        <div className="mb-4">
                            <span className="black medium fz18 poppins mb-3 d-block">
                                Please list and describe if you have any emergency medical knowledge or training, such as CPR or first aid.
                            </span>
                            <div className="d-flex align-items-center generalinfoRow">
                                <div className="w-100 me-4 inputdiv order2"><input className="w-100"></input></div>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <span className="black medium fz18 poppins mb-3 d-block">
                                Please describe any training or experience in conflict resolution or defusing a violent situation.
                            </span>
                            <div className="d-flex align-items-center generalinfoRow">
                                <div className="w-100 me-4 inputdiv order2"><input className="w-100"></input></div>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <span className="black medium fz18 poppins mb-3 d-block">
                                Please describe any other physical training or skills (defensive holds, combat training, tactical training, etc.) you have that will support your role providing personal security services.
                            </span>
                            <div className="d-flex align-items-center generalinfoRow">
                                <div className="w-100 me-4 inputdiv order2"><input className="w-100"></input></div>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <span className="black medium fz18 poppins mb-3 d-block">
                                Although the personal security position is un-armed, please describe if you have any firearms, weapons, taser, etc.
                            </span>
                            <div className="d-flex align-items-center generalinfoRow">
                                <div className="w-100 me-4 inputdiv order2"><input className="w-100"></input></div>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-4 mb-4"></hr>
                    </div>

                    <div className="personalInfo generalIfo">
                        <p className="blue semibold fz18 poppins mb-4"> Medical Status </p>
                        <div className="mb-4">
                            <span className="black medium fz18 poppins mb-3 d-block">
                                Do you take any medication that affects your nervous system or cognitive abilities? or otherwise impairs your ability to engage in strenuous physical activity
                            </span>
                            <div className="d-flex align-items-center generalinfoRow">
                                <div className="w-100 me-4 inputdiv order2"><input className="w-100"></input></div>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-4 mb-4"></hr>
                    </div>

                    <div className="personalInfo">
                        <p className="blue semibold fz18 poppins mb-4"> Physical Fitness Requirements </p>
                        <p className="grayopacity regular fz18 poppins mb-4 grayTitle">
                            There are required physical and mental skills that are essential functions needed to perform this position, summarized on the position description attached at the end of this questionnaire.    Please state whether you can perform these physical and mental skills required for the position.  If you need any assistance or accommodation to do so, please describe or demonstrate how you would perform the physical and mental requirements of this position with an accommodation.
                        </p>
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Scale four-foot wall
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Crawl through a tunnel/low lying obstacle for 10 feet
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Climb and go down stairs
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Walk a balance beam
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Carry 50 lbs weight up a flight of stairs
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Lift and carry and set down 50 lbs weight
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Run a mile in under 10 minutes
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Push ups (at least 4 repetitions)
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Bench press (at least 50 percent of your body weight)
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="black regular fz18 poppins d-block">
                                    Sit ups (at least 14 repetitions in one minute)
                                </span>
                                <div className="d-flex justify-content-end statusItem">
                                    <Button className="statusItemdiv">Yes</Button>
                                    <Button className="statusItemdiv">No</Button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <p className="grayopacity medium fz18 poppins mb-4 grayTitle">
                                If there are physical fitness tasks above you cannot meet, but could do with an accommodation, please describe or demonstrate how so.
                            </p>
                            <input className="w-100" style={{
                                height: '50px',
                                Border: '1px solid #C4C4C4',
                            }}></input>
                        </div>

                        <div className="mb-4 mt-5 text-center">
                            <Button className="applicationBtn"> Submit </Button>
                        </div>
                    </div>


                </div>
            </Container >
        </div >
    );
};

export default HeroApplication;