import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <Container>
        <div className="topFooter">
          <div className="row">
            <div className="col-sm-12">
              <div className="logo mB45">
                <Button
                  className="border-0 bg-transparent p-0"
                  onClick={() => {
                    navigate("hero-home");
                  }}
                >
                  <img src="./assets/img/logo.svg" alt="logo" />
                </Button>
              </div>
            </div>

            <div className="col-sm-12 col-md-3 col-lg-2">
              <div className="footConent">
                <p className="mb-0 fz16 medium white poppins">
                  Visit help center
                </p>
              </div>
            </div>
            <div className="col-md-3 col-lg-2">
              <div className="footConent">
                <div className="widgetTitle">Company</div>
                <ul>
                  <li>
                    <Link to="/company-home">About us</Link>
                  </li>
                  <li>
                    <Link to="/company-team"> Meet the team </Link>
                  </li>
                  <li>
                    <Link to="/newsroom">News</Link>
                  </li>
                  <li>
                    <Link to="/blog"> Blog </Link>
                  </li>
                  <li>
                    <a href="/#">Careers</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-2">
              <div className="footConent">
                <div className="widgetTitle">The Services</div>
                <ul>
                  <li>
                    <Link to="/">Personal protection</Link>
                  </li>
                  <li>
                    <Link to="/hero-home"> Be a Hero </Link>
                  </li>
                  <li>
                    <a href="/#">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3 col-lg-2">
              <div className="footConent">
                <div className="widgetTitle"> Safety </div>
                <ul>
                  <li>
                    <a href="/#"> Safety for all </a>
                  </li>
                  <li>
                    <Link to="/hero-safety"> Hero Safety </Link>
                  </li>
                  <li>
                    <Link to="/customer-safety"> Customer safety </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottomFooter">
          <div className="row align-items-end">
            <div className="col-sm-12 col-md-6">
              <div className="socialmedia">
                <ul>
                  <li>
                    <a href="/#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="footConent">
                <ul className="d-flex justify-content-end socialBtn mb-5">
                  <li>
                    <a href="/#">
                      <img src="assets/img/google-play.svg" alt="icon" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      {" "}
                      <img src="assets/img/app-store.svg" alt="icon" />{" "}
                    </a>
                  </li>
                </ul>
                <ul className="d-flex justify-content-end">
                  <li>
                    <Link to="customer-privacy-policy">Privacy</Link>
                  </li>
                  <li>
                    <a href="/#"> Accessibility </a>
                  </li>
                  <li>
                    <Link to="customer-terms-conditions">Terms</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
