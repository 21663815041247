import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { getNews, getNewsDetails } from "../api/News";
import Grid from "./Grid";
import { getFormattedDate, socialMediaURLs } from "../utils";

const NewsDetails = () => {
  const [newsDetails, setNewsDetails] = useState({});
  const [baseURL, setBaseURL] = useState("");
  const [newsItems, setNewsItems] = useState([]);
  const { slug } = useParams();
  const navigate = useNavigate();

  const handleApiData = async () => {
    try {
      const response = await getNewsDetails(slug);
      const newsData = response.data.data;
      setNewsDetails(newsData.newsDetails);
      setBaseURL(newsData.s3Base);

      const newsResponse = await getNews({
        page: 1,
        perPage: 4,
      });
      const items = newsResponse.data.data.newsList;
      const filteredItems = items.filter(news => news._id !== newsData.newsDetails._id);
      setNewsItems(filteredItems.slice(0, 3));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }

  useEffect(() => {
    handleApiData();
  }, [slug]);

  const handleShare = (type) => {
    const url = window.location.href;
    if (type === 'link') {
      navigator?.clipboard?.writeText(url);
    }
    else if (type === 'twitter') {
      window.open(`${socialMediaURLs[type]}${newsDetails.title}%20${url}`, "_blank");
    }
    else if (socialMediaURLs[type]) {
      window.open(`${socialMediaURLs[type]}${url}`, "_blank");
    }
  }

  const handleView = () => {
    navigate('/newsroom/news');
  }

  const handleViewDetails = (type) => {
    type && navigate(`/newsroom/news/${type}`);
  }

  return (
    <div>
      <div className="innerbanner companyNews3 sec_pd pt-0">
        <div className="innerHeader d-none d-lg-block">
          <Navbar expand="lg">
            <Container fluid>
              <Navbar.Brand
                as={Link}
                to="/customer-future"
                className="bold poppins white"
              >
                Company
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link as={Link} to="/what-hero">
                    About us
                  </Nav.Link>
                  <Nav.Link as={Link} to="/how-it-works">
                    Our Team
                  </Nav.Link>
                  <Nav.Link as={Link} to="/newsroom">
                    News
                  </Nav.Link>
                  <Nav.Link as={Link} to="/blog">
                    Blog
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <Container>
          <div className="companyNews_content pT70">
            <span className="fz16 medium poppins white">{getFormattedDate(newsDetails.created)}</span>
            <h1 className="white fz48 medium poppins pt-3 pb-3 text-capitalize">
              {newsDetails.title}
            </h1>
            {newsDetails?.author && <p className="mb-0 fz16 medium poppins white">
              – Written by {newsDetails.author}
            </p>}
            <div className="social_Icon d-flex d-lg-none">
              <div onClick={() => handleShare('facebook')} className="mx-3">
                <Image src="assets/img/facebook.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare('twitter')} className="mx-3">
                <Image src="assets/img/twitter.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare('email')} className="mx-3">
                <Image src="assets/img/mail0.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare('linkedin')} className="mx-3">
                <Image src="assets/img/LinkedIn.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("link")} className="mx-3">
                <Image src="assets/img/link0.svg"></Image>{" "}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="blogDetail_sec sec_pd">
        <Container>
          <Image className="blog-img" src={`${baseURL}${newsDetails.banner}`} />
          <div className="blogDetail_sec_content d-lg-flex align-items-start">
            <div className="social_Icon order2 mT150">
              <hr className="d-block d-lg-none"></hr>
              <span className="black fz14 medium poppins d-block mb-4 d-block d-lg-none">
                {" "}
                Share this post{" "}
              </span>
              <span className="grey fz14 medium poppins d-block mb-4 d-none d-lg-block">
                {" "}
                Share{" "}
              </span>
              <div onClick={() => handleShare('facebook')} className="my-3 pointer">
                <Image src="assets/img/facebook.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare('twitter')} className="my-3 pointer">
                <Image src="assets/img/twitter.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare('email')} className="my-3 pointer">
                <Image src="assets/img/mail0.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare('linkedin')} className="my-3 pointer">
                <Image src="assets/img/LinkedIn.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("link")} className="my-3 pointer">
                <Image src="assets/img/link0.svg"></Image>{" "}
              </div>
            </div>

            <div className="blogDetail_sec_contentinner pT80 order1">
              <p>
                {parse(newsDetails.description || '')}
              </p>
              {/* <div>
                <div className="editorBlock mT65 mB60">
                  <div className="editorBlock_card d-flex">
                    <Image src="assets/img/link0.svg"></Image>
                    <div className="editorBlock_card_content mL30">
                      <p className="fz20 black poppins bold mb-0">Editor's name</p>
                      <span className="fz20 black poppins regular">Editor's name Explanation of the editor's role: Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum. Lorem Ipsum dolor sit amet lorem ipsum.</span>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="fz20 black poppins regular mb-0">Posted by: Editor's name</p>
                  <p className="fz20 black poppins regular">Category: <b className="text-decoration-underline">Culture </b></p>
                </div>
              </div> */}
            </div>
          </div>



        </Container>
      </div>

      <div className="sec_pd">
        <Container>
          <h4 className="black poppins fz24 medium mB50 text-center latestnewstitle">
            The latest Hero news
          </h4>
          <Row>
            <>
              {newsItems?.length > 0 &&
                newsItems.map((item, index) => (
                  <Col key={index} sm={12} md={4}>
                    <div className="newsBox">
                      <div className="imageBox" >
                        <img src={`${baseURL}${item.banner}`} alt="NewsImage" className="img-fluid" />
                      </div>
                      <div className="newsBox_content" onClick={() => handleViewDetails(item?.slug)}>
                        <span className="black fz16 medium poppins">
                          {getFormattedDate(item.created)}{" "}
                        </span>
                        <p className="mb-0 black fz24 medium poppins mt-1">
                          <Link
                            to={item?.slug ? `/newsroom/news/${item?.slug}` : ''}
                            className="black text-decoration-none"
                          >
                            {item.title}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              <div className="text-center mT40">
                <button
                  onClick={handleView}
                  className="viewMore_text fz20 medium poppins black text-decoration-none bg-transparent border-0"
                >
                  View more news
                  <svg
                    class="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
                  </svg>
                </button>
              </div>
            </>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default NewsDetails;
