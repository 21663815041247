import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { getNews } from "../api/News";
import Grid from "./Grid";
import { getFormattedDate, socialMediaURLs } from "../utils";
import { getBlogDetail, getBlogs } from "../api/Blogs";

const BlogDetails = () => {
  const [blogDetails, setBlogDetails] = useState({});
  const [baseURL, setBaseURL] = useState("");
  const [blogItems, setBlogItems] = useState([]);
  const { slug } = useParams();
  const navigate = useNavigate();

  const handleApiData = async () => {
    try {
      const response = await getBlogDetail({ slug });
      const blogData = response.data.data;
      setBlogDetails(blogData.blogDetails);
      setBaseURL(blogData.s3Base);

      const blogList = await getBlogs({ page: 1, perPage:4 });
      const items = blogList.data.data.blogList;
      const filteredItems = items.filter(blog => blog._id !== blogData.blogDetails._id);
      setBlogItems(filteredItems.slice(0, 3));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    handleApiData();
  }, [slug]);

  const handleShare = (type) => {
    const url = window.location.href;
    if (type === "link") {
      navigator?.clipboard?.writeText(url);
    } else if (type === "twitter") {
      window.open(
        `${socialMediaURLs[type]}${blogDetails.title}%20${url}`,
        "_blank"
      );
    } else if (socialMediaURLs[type]) {
      window.open(`${socialMediaURLs[type]}${url}`, "_blank");
    }
  };

  const handleView = () => {
    navigate("/blog");
  };

  const handleViewDetails = (type) => {
    type && navigate(`/blog/${type}`);
  };

  return (
    <div>
      <div className="innerbanner companyNews3 sec_pd pt-0">
        <div className="innerHeader d-none d-lg-block">
          <Navbar expand="lg">
            <Container fluid>
              <Navbar.Brand
                as={Link}
                to="/customer-future"
                className="bold poppins white"
              >
                Company
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link as={Link} to="/what-hero">
                    About us
                  </Nav.Link>
                  <Nav.Link as={Link} to="/how-it-works">
                    Our Team
                  </Nav.Link>
                  <Nav.Link as={Link} to="/newsroom">
                    News
                  </Nav.Link>
                  <Nav.Link as={Link} to="/blog">
                    Blog
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <Container>
          <div className="companyNews_content pT70">
            <span className="fz16 medium poppins white">
              {/* {getFormattedDate(blogDetails.created)} */}
              {blogDetails?.categories?.join(", ")}
            </span>
            <h1 className="white fz48 medium poppins pt-3 pb-3 text-capitalize">
              {blogDetails.heading}
            </h1>
            {/* {blogDetails?.author && (
              <p className="mb-0 fz16 medium poppins white">
                – Written by {blogDetails.author}
              </p>
            )} */}
             <p className="mb-0 fz16 medium poppins white">
              {getFormattedDate(blogDetails.created)}
              </p>
            <div className="social_Icon d-flex d-lg-none">
              <div onClick={() => handleShare("facebook")} className="mx-3">
                <Image src="assets/img/facebook.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("twitter")} className="mx-3">
                <Image src="assets/img/twitter.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("email")} className="mx-3">
                <Image src="assets/img/mail0.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("linkedin")} className="mx-3">
                <Image src="assets/img/LinkedIn.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("link")} className="mx-3">
                <Image src="assets/img/link0.svg"></Image>{" "}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="blogDetail_sec sec_pd">
        <Container>
          <Image className="blog-img" src={`${baseURL}${blogDetails.banner}`} />
          <div className="blogDetail_sec_content d-lg-flex align-items-start">
            <div className="social_Icon order2 mT150">
              <hr className="d-block d-lg-none"></hr>
              <span className="black fz14 medium poppins d-block mb-4 d-block d-lg-none">
                {" "}
                Share this post{" "}
              </span>
              <span className="grey fz14 medium poppins d-block mb-4 d-none d-lg-block">
                {" "}
                Share{" "}
              </span>
              <div
                onClick={() => handleShare("facebook")}
                className="my-3 pointer"
              >
                <Image src="assets/img/facebook.svg"></Image>{" "}
              </div>
              <div
                onClick={() => handleShare("twitter")}
                className="my-3 pointer"
              >
                <Image src="assets/img/twitter.svg"></Image>{" "}
              </div>
              <div
                onClick={() => handleShare("email")}
                className="my-3 pointer"
              >
                <Image src="assets/img/mail0.svg"></Image>{" "}
              </div>
              <div
                onClick={() => handleShare("linkedin")}
                className="my-3 pointer"
              >
                <Image src="assets/img/LinkedIn.svg"></Image>{" "}
              </div>
              <div onClick={() => handleShare("link")} className="my-3 pointer">
                <Image src="assets/img/link0.svg"></Image>{" "}
              </div>
            </div>

            <div className="blogDetail_sec_contentinner pT80 order1">
              <p>{parse(blogDetails.description || "")}</p>
              {blogDetails?.author?.name && (<div>
                <div className="editorBlock mT65 mB60">
                  <div className="editorBlock_card d-flex">
                    <Image src={`${baseURL}${blogDetails?.author?.image}`}></Image>
                    <div className="editorBlock_card_content mL30">
                      <p className="fz20 black poppins bold mb-0">
                      {blogDetails?.author?.name}
                      </p>
                      <span className="fz20 black poppins regular">
                        {parse(blogDetails?.author?.bio)}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="fz20 black poppins regular mb-0">
                    Posted by: {blogDetails?.author?.name}
                  </p>
                  <p className="fz20 black poppins regular">
                    Category:&nbsp;
                    {blogDetails?.categories?.map((item) => (
                      <b className="text-decoration-underline">{item}</b>
                    ))}
                  </p>
                </div>
              </div>)}
            </div>
          </div>
        </Container>
      </div>

      <div className="sec_pd">
        <Container>
          <h4 className="black poppins fz24 medium mB50 text-center latestnewstitle">
            The latest Hero blog
          </h4>
          <Row>
            <>
              {blogItems?.length > 0 &&
                blogItems.map((item, index) => (
                  <Col key={index} sm={12} md={4}>
                    <div className="newsBox">
                      <div className="imageBox">
                        <img
                          src={`${baseURL}${item.banner}`}
                          alt="NewsImage"
                          className="img-fluid"
                        />
                      </div>
                      <div
                        className="newsBox_content"
                        onClick={() => handleViewDetails(item?.slug)}
                      >
                        <span className="black fz16 medium poppins">
                          {getFormattedDate(item.created)}{" "}
                        </span>
                        <p className="mb-0 black fz24 medium poppins mt-1">
                          <Link
                            to={item?.slug ? `/blog/${item?.slug}` : ""}
                            className="black text-decoration-none"
                          >
                            {item.heading}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              {/* <div className="text-center mT40">
                <button
                  onClick={handleView}
                  className="viewMore_text fz20 medium poppins black text-decoration-none bg-transparent border-0"
                >
                  View more news
                  <svg
                    class="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
                  </svg>
                </button>
              </div> */}
            </>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogDetails;
