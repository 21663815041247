import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const WhatHero = () => {
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand
              as={Link}
              to="/what-hero"
              className="bold poppins black"
            >
              What is Hero?
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero" active>
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/how-it-works">
                  How It Works
                </Nav.Link>
                <Nav.Link as={Link} to="/fees">
                  Fees
                </Nav.Link>
                <Nav.Link as={Link} to="/getstarted">
                  Get Started
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-future">
                  The future of Hero
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="protection sec_pd whathero_sec1">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="sectionTitle">
                <h2 className="black poppins fz36 bold mb-3">
                  Take a safer <br></br> step with Hero
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  The convenient personal security app,<br></br>
                  Our app and website is your digital home <br></br>for safety.
                  Request a Hero in a click, easy to <br></br>use and full of
                  useful information, it puts <br></br>personal safety back into
                  your hands.
                </p>
                {/* <Link
                  href="/#"
                  className="btn btn-outline btn-outline-dark mT30"
                >
                  Learn More
                </Link> */}
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="imgWrap text-center">
                <Image src="assets/img/whathero.png"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="ondemand sec_pd bgdarkGray">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="imgWrap text-center">
                <Image
                  className="w-100"
                  src="./assets/img/ondemand.png"
                ></Image>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="sectionTitle p-5">
                <h2 className="black poppins fz36 bold mb-3">
                  On-demand security, <br></br> in everyone’s reach
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  Our app enabled platform uses on-demand, secure mapping and
                  tracking technology to link customers with Hero operatives.
                  Make your journey safer with HERO.
                </p>
                <div className="mt-3">
                  <Link to="/how-it-works" className="d-inline black text-decoration-none">
                    {" "}
                    Find out how a journey works{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="whyHero_Wors sec_pd">
        <Container>
          <div className="whyHero_Wors_title fz40 bold poppins black mB70">
            Why Hero works
          </div>
          <Row>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/journey-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Journey on-demand
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Unlike most other security services, Hero is hassle-free. Go
                    from sign-up to Hero by your side in minutes.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/pay-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Journey on-demand
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Unlike most other security services, Hero is hassle-free. Go
                    from sign-up to Hero by your side in minutes.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/support-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Journey on-demand
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Unlike most other security services, Hero is hassle-free. Go
                    from sign-up to Hero by your side in minutes.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/protective-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Journey on-demand
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Unlike most other security services, Hero is hassle-free. Go
                    from sign-up to Hero by your side in minutes.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/safeguard-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Journey on-demand
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Unlike most other security services, Hero is hassle-free. Go
                    from sign-up to Hero by your side in minutes.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/mapping-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Journey on-demand
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Unlike most other security services, Hero is hassle-free. Go
                    from sign-up to Hero by your side in minutes.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="ondemand sec_pd bgdarkGray">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="imgWrap text-center">
                <Image className="w-100" src="./assets/img/hero.png"></Image>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="sectionTitle p-5">
                <h2 className="black poppins fz36 bold mb-3">
                  Totally dependable. <br></br> Utterly reliable.
                </h2>
                <br></br>
                <p className="mb-0 fz16 regular poppins black lh25">
                  <b> Heroes are</b> vetted, dedicated and in some cases
                  significantly more highly trained individuals, dedicated to
                  the safety of others.
                </p>
                <br></br>

                <p className="mb-0 fz16 regular poppins black lh25">
                  <b> Heroes are </b>compassionate to the needs of those they
                  protect.
                </p>
                <br></br>
                <p className="mb-0 fz16 regular poppins black lh25">
                  <b> Heroes are</b> consummate professionals who are courteous
                  not just to the client but to everyone. Our Heroes as a
                  minimum requirement must hold a security license, but
                  frequently are police, ex-forces or have advanced security
                  training.
                </p>
                <br></br>
                <p className="mb-0 fz20 bold poppins black">
                  A Hero dares to make a difference.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="heroMission_Sec sec_pd">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="sectionTitle pR70">
                <h2 className="black poppins fz36 bold mb-3">
                  The Hero Mission
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  Today we live in a world where feeling safe has become
                  increasingly difficult. But what if we could feel safe and
                  protected on our streets? What if we could always have a Hero
                  around us when we need one? Our lives would no longer be in
                  danger. With Hero, wherever you go, we go with you paving a
                  safer way for you and your family.
                </p>
                <br></br>
                <p className="mb-0 fz16 regular poppins black lh25">
                  At any time, Hero’s are available for you with one mission, to
                  protect you. From the moment your Hero joins you, we take care
                  of your safety so the only thing you need to worry about, is
                  enjoying life.
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="imgWrap text-center">
                <Image src="assets/img/hero-guard.png"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserve one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>I am a Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section>

      <section className="signup_tomake sec_pd">
        <Container>
          <Col md={12} lg={5} sm={12}>
            <div className="linkBox">
              <Link className="fz40 bold poppins black text-decoration-none d-block">
                Sign up to make a journey
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="30"
                  height="30"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </Col>
        </Container>
      </section>
    </div>
  );
};

export default WhatHero;
