import React from "react";
import {
  Container,
  Image,
  Col,
  Row,
  Nav,
  Navbar,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
const CompanyHome = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="company-home" className="bold poppins black">
              Company
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/company-founder" active>
                  About us
                </Nav.Link>
                <Nav.Link as={Link} to="/company-team">Our Team</Nav.Link>
                {/* <Nav.Link as={Link} to="fees">News</Nav.Link> */}
                {/* <Nav.Link as={Link} to="getstarted">Blog</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="safetyTechnology companyBanner">
        <Container>
          <Row>
            <Col sm={12} lg={5}>
              <div className="sectionTitle">
                <h2 className="black poppins fz48 bold d-block d-lg-none">
                  About Us
                </h2>
                <h2 className="black poppins fz48 bold d-none d-lg-block">
                  We want a world where the streets are safer...
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="foreveryBody sec_pd d-none d-lg-block">
        <Container>
          <div className="sectionTitle text-center">
            <span className="topline"></span>
            <span className="bottomline"></span>
            <h2 className="black poppins fz48 bold mB20">...for everybody</h2>
            <p className="black regular fz20 poppins lh33">
              Security is a basic need. We believe in the fundamental <br></br>
              right to go about our lives without fear or worry of assault.
            </p>
          </div>
        </Container>
      </section>

      <section className="foreveryBody_mobile sec_pd d-block d-lg-none">
        <Container>
          <div className="">
            <h2 className="black poppins fz20 bold mB20">
              We want to reimagine a world where the streets are safer for
              everybody
            </h2>
            <p className="black regular fz14 poppins lh25">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Read our
              mission statement
            </p>
          </div>
        </Container>
      </section>

      <section className="yourSafety sec_pd communitySafer">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={6} sm={12} className="order2">
              <div className="imgWrap text-center">
                <Image src="assets/img/community.png"></Image>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="d-lg-none d-block mT30">
                <h2 className="black poppins fz20 bold mb-2">
                  A word from our founder
                </h2>
                <p className="fz14 regular poppins black lh22">
                  Read about our team’s commitment to provide everyone on our
                  platform with the technology that can help them move around
                  our cities safely.
                </p>
                <Link className="mT20 d-inline-block">Read Now</Link>
              </div>
              <div className="sectionTitle d-lg-block d-none">
                <h2 className="black poppins fz36 bold mb-4">
                  We’re building a safer community
                </h2>
                <p className=" fz15 regular poppins black lh25">
                  With increasing crime rates and an overstretched police force,
                  we see a need to build a wider community of dedicated people
                  who care about the safety and security of others.
                </p>
                <p className="mb-0 fz15 regular poppins black lh25">
                  A community where personal crime prevention is at the heart of
                  making our streets safer. A community enabled through
                  technology. A community of Heroes, so together, we never have
                  to walk alone.,/
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="ourCommitment d-none d-lg-block">
        <Container>
          <div className="founderInfo_intro mt-0">
            <Row className="g-0">
              <Col sm={12} md={6}>
                <div className="founderInfo_intro_content h-100">
                  <div>
                    <h2 className=" poppins fz36 bold mB30">Our commitment</h2>
                    <p className="mb-0 fz15 regular poppins  lh25">
                      Read about our team’s commitment to<br></br> provide
                      everyone on our platform with the <br></br>technology that
                      can help them move around<br></br>
                      our cities safely.
                    </p>
                    <Button
                      onClick={() => {
                        navigate("/company-founder");
                      }}
                      className="btn btn-outline btn-outline-light mT30"
                    >
                      Read now
                    </Button>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="imgWrap h-100">
                  <Image
                    className="h-100"
                    src="assets/img/commintment.png"
                  ></Image>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="howhero_help sec_pd mobileFont ">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/personal1.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Personal protection and beyond
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    As well as everyone being able to walk the streets safely{" "}
                    <br></br>accompanied by a Hero, we aim for our maps to serve
                    as a safety <br></br>
                    landscape of the city where people can report crimes and
                    dangerous <br></br>areas to avoid. Looking to the future,
                    our Hero’s will be equipped with <br></br>
                    first aid kits, defibrillation equipment and body cameras as
                    we build <br></br>support to complement, not replace our
                    emergency services.
                  </p>
                  <div>
                    <Button
                      onClick={() => {
                        navigate("/how-it-works");
                      }}
                      className="bg-transparent border-0 shadow-0 fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block linkbtn"
                    >
                      How to use the Hero app
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>

            <div className="mt-5"></div>
            <Col sm={12} md={12} lg={6} className="order2">
              <div className="whyHero_Wors_card p-0 shadow-none pR40 mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Your safety is our priority
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    Whether you’re walking with one of our Heroes or are the one
                    <br></br>
                    providing protection, your safety is essential. We are
                    committed to <br></br>doing our part, and technology is key
                    to our intelligent service.<br></br> We partner with safety
                    advocates and develop new technologies <br></br>and systems
                    to help improve safety and help make it easier <br></br>for
                    everyone to regain the freedom of a safer city.
                  </p>
                  <Link to="/customer-safety" className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read more about safety
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="order1">
              <figure>
                <Image src="assets/img/personal2.png"></Image>
              </figure>
            </Col>
            <div className="mt-5"></div>
          </Row>
        </Container>
      </section>

      <section className="sec_pd company_team companyInfo pt-0 mobileFont">
        <Container>
          <h4 className="fz40 bold poppins black team_title mB60">
            Company info
          </h4>
          <Row>
            <Col sm={12} md={4}>
              <div className="teamData">
                <Image
                  className="w-100"
                  src="assets/img/companyinfo1.png"
                ></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <p class="mb-0 fz18 bold poppins black">
                      {" "}
                      Who leads our Hero’s
                    </p>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    We’re building a culture within HERO that emphasizes looking
                    after each other, no matter what, for clients, Heroes and
                    employees. Find out more about the team that’s leading the
                    way.
                  </p>
                  <div>
                    <Button
                      onClick={() => {
                        navigate("/company-team");
                      }}
                      className="bg-transparent border-0 shadow-0 fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block linkbtn"
                    >
                      See our leadership
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="teamData">
                <Image
                  className="w-100"
                  src="assets/img/companyinfo2.png"
                ></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <span class="mb-0 fz18 bold poppins black">
                      Getting diversity right
                    </span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    We respect and protect all of our family, both client and
                    Hero alike. Through diversity and inclusion, we seek to
                    build a wide cross section of representation within our Hero
                    community, where all voices are heard and everyone feels
                    valued.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="teamData pr-0">
                <Image
                  className="w-100"
                  src="assets/img/companyinfo3.png"
                ></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <span class="mb-0 fz18 bold poppins black">
                      Acting with integrity
                    </span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    Our service is built on trust, that’s why we set the highest
                    standards of conduct for our team. As well as employing
                    people with the right protection skills, we look for
                    individuals who have empathy, compassion and a desire to
                    always be honest and do the right thing in serving the
                    community they represent.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="crimePicture sec_pd opacity-25 bg-transparent pt-0 mobileFont">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/crime-ic.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Crime statistics
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Crime statistics NYPD data showed a rapid increase in
                    murder, rape, robbery, felony assault, burglary, and grand
                    larceny auto in 2022. Felony assaults surpassed 22,000
                    incidents and murders neared 500 last year. What does this
                    mean for New Yorkers?
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read our blog
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/spot-crime.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Hot spots of crime
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    See where crime rates are highest in New York using the NYC
                    crime map. If crime in these areas are affecting you,
                    there’s something you can do about it.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read our blog
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
          </Row>
        </Container>
      </section> */}
      <section className="companyBottom_banner safetyTechnology">
        <Container>
          <Row>
            <Col sm={12} lg={5}>
              <div className="sectionTitle white">
                <h2 className=" poppins fz48 bold mB30">
                  Create a safer<br></br> world with us
                </h2>
                <p className="mb-0 fz15 regular poppins  lh25">
                  We are always looking for people with the right qualities to
                  be a Hero, particularly in our launch area of Manhattan, New
                  York. If you have a security license, are a member or
                  ex-member of the police, military or security forces or think
                  you have what it takes to help and protect others, we want to
                  hear from you.
                </p>
                <Link
                  href="#"
                  className=" fz15 regular poppins  mT30 white text-decoration-none d-inline-block"
                >
                  Apply now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CompanyHome;
