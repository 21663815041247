import React from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomerContact = () => {
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="/Whatis-hero" className="bold poppins black">
              What is Hero?
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero">Overview</Nav.Link>
                <Nav.Link as={Link} to="/how-it-works">How It Works</Nav.Link>
                <Nav.Link as={Link} to="/fees">Fees</Nav.Link>
                <Nav.Link as={Link} to="/getstarted">Get Started</Nav.Link>
                <Nav.Link as={Link} to="/customer-future">The future of Hero</Nav.Link>
                <Nav.Link as={Link} to="/customer-contact" active>
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="sec_pd contactHero align-items-start">
        <Container>
          <Row>
            <Col md={5} sm={12}>
              <div className="sectionTitle mT70">
                <h2 className="black poppins fz48 bold mb-4">Contact Hero</h2>
                <p className="mb-0 fz24 regular poppins black lh36">
                  Do you have questions about using <br></br>our service or app?
                  We’re here for<br></br> whatever you need.
                </p>
              </div>
            </Col>
            <Image
              className="mapView "
              src="./assets/img/contact-support.png"
            ></Image>
          </Row>
        </Container>
      </section>

      <section className="getmore_info sec_pd">
        <Container>
          <div class="getmore_info_title fz40 bold poppins black mB40">
            Get more information
          </div>
          <Row>
            <Col md={6} sm={12}>
              <div class="whyHero_Wors_card p-0 shadow-none">
                <Image src="assets/img/icon-get.svg"></Image>
                <div class="whyHero_Wors_cardcontent mT30">
                  <h4 class="mB20 fz20 bold poppins  black">
                    Get up to date on security issues
                  </h4>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Discover what our community is saying, read articles on
                    aspects of security and learn about some useful safety
                    resources.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Learn more{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div class="whyHero_Wors_card p-0 shadow-none">
                <Image src="assets/img/get-help.svg"></Image>
                <div class="whyHero_Wors_cardcontent mT30">
                  <h4 class="mB20 fz20 bold poppins  black">
                    Get up to date on security issues
                  </h4>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Discover what our community is saying, read articles on
                    aspects of security and learn about some useful safety
                    resources.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Get Help
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <hr></hr>
        </Container>
      </section>
    </div>
  );
};

export default CustomerContact;
