import React from "react";
import { Button, Col, Container, Form, Image, Row, } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomersignupProcess4 = () => {
    return (
        <div className="customer_signup sec_pd">
            <Container>
                <div className="customer_signup_process paymentmethod">
                    <div className="processTitle black medium fz20 poppins text-center mB20"> Add payment method </div>
                    <div className="downloadApp_div m-auto text-center mt-5">
                        <div className="cardRow d-flex justify-content-center">
                            <Image className="card-img active" src="assets/img/bankcard.png"></Image>
                            <Image className="card-img" src="assets/img/apple-pay.png"></Image>
                        </div>
                        <div className="cardData">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="fz16 medium poppins d-block text-start"> Card Number </Form.Label>
                                <Form.Control type="number" placeholder="1234 5678 9123 4567..." />
                            </Form.Group>
                            <Row>
                                <Col sm={12} md={7}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="fz16 medium poppins d-block text-start"> Cardholder Name </Form.Label>
                                        <Form.Control type="text" placeholder="Alex Cooper..." />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={5}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="fz16 medium poppins d-block text-start"> Zip Code </Form.Label>
                                        <Form.Control type="text" placeholder="Alex Cooper..." />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={7}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="fz16 medium poppins d-block text-start">Expiration Date </Form.Label>
                                        <Form.Control type="text" placeholder="Alex Cooper..." />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={5}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="fz16 medium poppins d-block text-start"> CVC </Form.Label>
                                        <Form.Control type="text" placeholder="123" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="btnRow">
                            <Button variant="primary" className="btn btn-primary w-100 mb-3">
                                Add Card
                            </Button>
                            <Button variant="primary-outline" className="btn btn-outline-primary w-100">
                                Choose Later
                            </Button>
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CustomersignupProcess4;
