import React from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const HeroContact = () => {
  return (
    <div>
      <div className="innerMobile d-block d-lg-none p-4 pb-0">
        <a
          href="#home"
          className="bold fz16 poppins black text-decoration-none"
        >
          What is Hero?
        </a>
      </div>
      <section className="sec_pd contactHero align-items-start heroContact">
        <Container>
          <Row>
            <Col md={5} sm={12} className="order2">
              <div className="sectionTitle mT70">
                <h2 className="black poppins fz48 bold mb-4">Contact Hero</h2>
                <p className="mb-0 fz24 regular poppins black lh36">
                  Do you have questions about using <br></br>our service or app?
                  We’re here for<br></br> whatever you need.
                </p>
              </div>
            </Col>
            <Image
              className="mapView "
              src="assets/img/contact-support.png"
            ></Image>
          </Row>
        </Container>
      </section>

      <section className="getmore_info sec_pd hero_getmore_info">
        <Container>
          <div class="getmore_info_title fz40 bold poppins black mB40">
            Get more information
          </div>
          <Row>
            <Col md={6} sm={12}>
              <div class="whyHero_Wors_card p-0 shadow-none">
                <Image src="assets/img/icon-get.svg"></Image>
                <div class="whyHero_Wors_cardcontent mT30">
                  <h4 class="mB20 fz20 bold poppins  black">
                    Get up to date on security issues
                  </h4>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Discover what our community is saying, read articles on
                    aspects of security and learn about some useful safety
                    resources.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Learn more{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div class="whyHero_Wors_card p-0 shadow-none">
                <Image src="assets/img/get-help.svg"></Image>
                <div class="whyHero_Wors_cardcontent mT30">
                  <h4 class="mB20 fz20 bold poppins  black">
                    Get up to date on security issues
                  </h4>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Discover what our community is saying, read articles on
                    aspects of security and learn about some useful safety
                    resources.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Get Help
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md={6} sm={12}>
              <div className="herocontact_data py-4">
                <div class="getmore_info_title fz40 bold poppins black mB40">
                  In person support
                </div>
                <div class="whyHero_Wors_cardcontent mT30">
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Our on-site supervisors in New York can be contacted to book
                    a face-to-face meeting to discuss any issues. Also, our
                    private investigation company can look into any more serious
                    incidents, this can be arranged through our help-center.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Book an appointment
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md={6} sm={12}>
              <div className="herocontact_data mt-3">
                <div class="getmore_info_title fz40 bold poppins black mB40">
                  Phone support
                </div>
                <div class="whyHero_Wors_cardcontent mT30">
                  <p class="mb-3 fz15 regular poppins lh22 black">
                    The phone support line is available from 6 a.m.-10 p.m.
                  </p>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    To speak to an agent, go to Help in your Hero app, then tap
                    Call Support.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HeroContact;
