import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import alert from "../assets/images/alert.svg";
import answerQuestions from "../assets/images/answerQuestions.svg";
import chat from "../assets/images/chat.svg";
import professional from "../assets/images/professional.svg";
import DownloadApp from "../components/DownloadApp";
import ExploreMore from "../components/ExploreMore";
import Assignment from "../assets/images/explore-more.svg";

const AssignmentTips = () => {
  const assignmentText = "Accepting and taking assignments" || "";

  return (
    <div>
      <div className="innerMobile d-block d-lg-none p-4 pb-0">
        <a
          href="#home"
          className="bold fz16 poppins black text-decoration-none"
        >
          What is Hero?
        </a>
      </div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero?
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="d-none d-lg-block"
            >
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app" active>
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Sign up
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="getstarted sec_pd mt-4 mt-lg-0">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            Tips for a 5 star assignment{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="./assets/img/tips1.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                {" "}
                1. Be ready to respond to an alert promptly{" "}
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Clients are more likely to tip and leave a good review if their
                needs are met promptly and they are not waiting around in a
                vulnerable position, waiting for a Hero.{" "}
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Sign up now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={chat || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Greet the client and speak courteously at all times
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Your manner in how you greet and communicate with the client
                leaves a lasting impression and is representative of how they
                judge the HERO service and how they connect with you and feel at
                ease in your company.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={professional || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                3. Present a professional appearance
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Your physical appearance is key to projecting a professional
                air. Always look smart when attending an assignment to reassure
                the client from first glance that you are a professional, in
                control and are to be trusted. Also, be conscious of body odor
                especially in hot weather and ensure personal hygiene is part of
                your preparation routine.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={alert || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Always remain alert on your journey
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Your client is trusting you to be aware of any potentially
                threatening situations and to neutralize them whenever possible.
                The intent is for the client to have a seamless journey without
                incident. This is more likely when you are always ready to use
                your skills to identify, anticipate and avoid situations, or
                when pressed to intervene decisively to protect your client.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={answerQuestions || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Offer advice, answering questions if asked
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Clients may want to communicate with you on greeting or during
                your journey. Part of the service is to reassure and make the
                client feel safe. If they have any questions about the service
                or aspects of safety, answer them courteously to build a rapport
                with the client and to help assess their needs.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Download guide
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>
        </Container>
      </section>

      {/* <DownloadApp /> */}
      <ExploreMore text={assignmentText || " "} image={Assignment || ""} />
    </div>
  );
};

export default AssignmentTips;
