import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  NavItem,
  Row,
  Tab,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="homeBanner">
        <Container fluid>
          <div className="tabDesign ms-auto" defaultActiveKey="first">
            <Tab.Container>
              <Nav variant="pills" id="pills-tab">
                <NavItem role="presentation">
                  <Nav.Link active eventKey="first">
                    <Image src="assets/img/security.svg" />
                    Get Protection
                  </Nav.Link>
                </NavItem>
                <NavItem role="presentation">
                  <Nav.Link as={Link} to="/hero-home" eventKey="second">
                    <img src="assets/img/h-ic.svg" alt="img" />
                    Be a Hero
                  </Nav.Link>
                </NavItem>
              </Nav>
              <Tab.Content>
                <Tab.Pane active eventKey="first">
                  <h4>On-demand personal security</h4>
                  <Button
                    variant="outline-light"
                    type="button"
                    className="btn btn-outline btn-outline-light mt-4"
                    onClick={() => {
                      navigate("/hero-signup");
                    }}
                  >
                    Sign up now
                  </Button>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>
      </section>
      <section className="weHero sec_pd bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              We all deserve a <span className="text-uppercase">Hero</span>
            </h2>
            <p className="mb-0 fz22 regular poppins black lh33">
              HERO is a revolutionary new on-demand personal bodyguard app.
              <br />
              Quick and cost-effective, it’s a convenient way to request
              <br />
              personal security when you’re traveling around your city.
            </p>
          </div>
          <div className="imgWrap text-center mT70">
            <Image src="assets/img/wehero.svg"></Image>
          </div>
        </Container>
      </section>

      <section className="beSafe sec_pd">
        <Container>
          <Row className="align-items-center h-100 ">
            <Col sm={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz36 bold mb-3">
                  We all deserve a <span className="text-uppercase">Hero</span>
                </h2>
                <p className="mb-0 fz16 regular poppins white lh25">
                  HERO is a revolutionary new on-demand personal bodyguard app.
                  Quick and cost-effective, it’s a convenient way to request
                  personal security when you’re traveling around your city.
                </p>
                <Button
                  onClick={() => {
                    navigate("what-hero");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="yourSafety sec_pd">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={5} sm={12} className="order2">
              <div className="imgWrap text-center mT70">
                <Image src="assets/img/safety.svg"></Image>
              </div>
            </Col>
            <Col md={5} sm={12} className="order1">
              <div className="sectionTitle">
                <h2 className="black poppins fz36 bold mb-3">
                  Your safety. Our priority.
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  We’re there for you via the app and our community to help you
                  stay safe, before, while you’re getting around the city and
                  after every journey. When you need direct help, HERO gives you
                  24/7 support.
                </p>
                <Button
                  onClick={() => {
                    navigate("customer-safety");
                  }}
                  className="btn btn-outline btn-outline-primary mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="beSafe sec_pd lesscrime">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz36 bold mb-3">
                  Safe streets. Less crime.
                </h2>
                <p className="mb-0 fz16 regular poppins white lh25">
                  Why do we need HERO? To combat one of America’s biggest
                  <br></br>
                  problems. Crime is on the rise and while we totally support{" "}
                  <br></br>
                  our police department's efforts to tackle crime, we can all do{" "}
                  <br></br>
                  more. HERO is a way of preventing crime, reducing assaults and{" "}
                  <br></br>
                  allows people to feel safer on their streets.
                </p>

                <Button
                  onClick={() => {
                    navigate("whywe-need");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="workus">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col sm={5}>
              <div className="imgWrap">
                <Image src="assets/img/workus.png"></Image>
              </div>
            </Col>
            <Col sm={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz36 bold mb-3">
                  Be a Hero. Work with us.
                </h2>
                <p className="mb-0 fz16 regular poppins white lh25">
                  Do you have what it takes to be a Hero? Register now and earn
                  using your protection skills. Because everyone
                  <br></br>
                  deserves a Hero and that Hero could be you!
                </p>
                <Button
                  onClick={() => {
                    navigate("hero-overview");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="protection sec_pd">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={5} sm={12}>
              <div className="sectionTitle">
                <h2 className="black poppins fz36 bold mb-3">
                  We’re revolutionising <br></br> personal protection.
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  Behind every innovation, there’s a team with<br></br> passion
                  and vision. Our aim is to transform every<br></br> city, where
                  feeling safe becomes the new normal.<br></br> Meet the team
                  that’s making it happen.
                </p>
                <Button
                  onClick={() => {
                    navigate("company-home");
                  }}
                  className="btn btn-outline btn-outline-dark mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
            <Col md={5} sm={12}>
              <div className="imgWrap text-center">
                <Image src="assets/img/personal.png"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserve one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>I am a Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default Home;
