import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { uploadFile, uploadS3File } from "../api/util";
import { getHeroSignupProgress, uploadHeroDocs } from "../api/hero";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const HeroSignupStep3 = () => {
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState();
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true)
    const S3BaseUrl = localStorage.getItem('S3BaseUrl') || '';
    const [isUploading, setIsUploading] = useState(false);
    //get user uploaded data;
    useEffect(() => {
        getHeroSignupProgress().then(response => {
            if (response.data.success) {
                const heroRes = response?.data?.data?.profilePhoto || {};
                if (heroRes.isUploaded) {
                    setSelectedFiles(heroRes?.image)
                }
            }
        })
    }, [])

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return false
        //set uploading true
        setIsUploading(true)

        try {
            const response = await uploadFile({
                location: 'users/',
                type: "IMAGE",
                count: 1
            })
            const webUrlData = response.data.data[0];
            const { filename, preview, url } = webUrlData || {};

            const requestOptions = {
                method: 'PUT',
                headers: {
                    "Content-Type": file.type
                },
                body: file,
                redirect: 'follow'
            };

            const result = await uploadS3File({
                url,
                requestOptions
            })
            setSelectedFiles(filename);
            //enable submit button
            setIsSubmitBtnDisabled(false);
            //set uploading false
            setIsUploading(false)
        }
        catch (err) {
            console.log(err);
            //set uploading false
            setIsUploading(false)
        }
    }

    const handleSubmit = async () => {
        const response = await uploadHeroDocs({ type: 'PROFILE', url: [selectedFiles] });
        if (response?.data?.success) {
            navigate('/hero-signup-progress')
        }
    }
    return (
        <div className="customer_signup sec_pd">
            <Container>

                <div className="uploadyourProfile">
                    <div className="processTitle black medium fz20 poppins text-center mB40">
                        <NavLink className="d-flex align-items-center text-decoration-none text-dark" to="/hero-signup-progress">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14 18L8 12L14 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Back
                        </NavLink>
                        Upload your profile picture </div>
                    <p className="fz16 regular poppins black">Your profile picture helps people recognize you. Your profile photo cannot be changed once submitted.</p>

                    <span >  Tips for your profile picture </span>
                    <ul className="m-0 numberList ps-3">
                        <li> Ensure you are looking at the camera directly with your eyes and mouth clearly visible.</li>
                        <li> Make sure the photo is well lit, without reflections and in focus. </li>
                        <li> Photos of a photo, filters or anything that modifies the original photo are not accepted. </li>
                    </ul>
                    {selectedFiles && <div className="profilePic" style={{
                        backgroundImage: `url(${S3BaseUrl}${selectedFiles})`
                    }}></div>}

                    <div className="btnRow w-50 m-auto mt-3">
                        <div className={`uploadbtn ${isUploading ? 'disabled' : ''}`}>
                            {isUploading ? 'Uploading...' : 'Upload profile photo'}
                            {isUploading && <span class="spinner-border spinner-border-sm text-white ms-1" role="status" aria-hidden="true"></span>}
                            <input type="file" onChange={handleFileUpload} multiple />
                        </div>
                        <Button className="submit-btn mt-3" onClick={handleSubmit} disabled={isSubmitBtnDisabled}>
                            Submit
                        </Button>
                    </div>

                </div>
            </Container>
        </div>
    );
};

export default HeroSignupStep3;
