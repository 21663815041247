import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero?
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero">Overview</Nav.Link>
                <Nav.Link as={Link} to="/how-it-works" active>
                  How It Works
                </Nav.Link>
                <Nav.Link as={Link} to="/fees">Fees</Nav.Link>
                <Nav.Link as={Link} to="/getstarted">Get Started</Nav.Link>
                <Nav.Link as={Link} to="/customer-future">The future of Hero</Nav.Link>
                <Nav.Link as={Link} to="/customer-contact">Contact us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="safetyTechnology how_it sec_pd">
        <Container>
          <Row className="align-items-center h-100 justify-content-end">
            <Col sm={12} lg={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz48 bold mB40">
                  Simple and <br></br> easy to book
                </h2>
                <p className="mb-0 fz24 medium poppins white lh36">
                  From requesting a Hero in your immediate area to checking your
                  receipt, follow our step by step process to discover how easy
                  <br></br> it is to use the HERO app.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="getstarted sec_pd mt-4 mt-lg-0">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            Requesting a Hero{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request1.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                {" "}
                1. Request your Hero{" "}
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Your current location is captured and you enter the predicted
                duration that you need the Hero service for, along with whether
                you need a uniformed or incognito service and your preferred
                Hero position (beside, behind or shadow) then hit the ‘order a
                Hero’ button.
              </span>
              {/* <Link
                href="#"
                className="opacity-25 fz15 poppins semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request2.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Connect with a Hero
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Just like other popular on-demand apps the map will show the
                Hero operatives in your immediate area and will default to the
                closest available Hero, indicating the distance and time that
                the Hero is away from you. You can track your Hero’s progress,
                check out the Hero’s profile and chat messages can be
                sent/received between you both.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black opacity-25"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request3.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">3. Meet your Hero</p>
              <span className="poppins fz14 regular d-block lh25">
                Your Hero will greet you as they approach by your identified
                title and will present their Hero identity card and security
                guard license to confirm their identity. Please check thoroughly
                to ensure you are happy with their identity before proceeding.
                Your Hero will check that you’re OK and answer any questions you
                may have.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold opacity-25 mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request4.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Be escorted safely until you decide
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Your Hero will accompany you on your route at the designated
                position and will be vigilant of any situation, anticipating and
                avoiding any perceived threat where possible.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins opacity-25 semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request5.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Ending the service
              </p>
              <span className="poppins fz14 regular d-block lh25">
                The Hero will notify you when there are 5 minutes left of your
                service duration time, you can then either choose to extend your
                time or end the service when the time is up. You can then click
                to end service.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold  opacity-25 mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request6.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                6. Leave a rating and review
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Upon completion of the service you have the opportunity to leave
                a rating, a review or compliment or raise a report issue. Rating
                and commenting on our service helps to improve our offering for
                your next use and for others. Incident reports will be reviewed
                and followed up accordingly.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold  opacity-25 mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/request7.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                7. Payment and receipts
              </p>
              <span className="poppins fz14 regular d-block lh25">
                You also have the option to leave a tip for the Hero after
                leaving a rating. An itemized receipt will be shown for the
                completed service including any extended time or journey
                expenses and can be seen in your service history.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold  opacity-25 mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default HowItWorks;
