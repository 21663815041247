import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { heroSignup } from "../api/hero";

const HeroSignup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            phone: '',
            invitationCode: '',
            termsAndConditions: false,
            contactAgree: false
        },
        validationSchema: yup.object().shape({
            fullName: yup.string().required("Please enter full name"),
            email: yup.string().email("Please enter valid email").required("Please enter email"),
            password: yup.string().required("Please enter password").min(8, 'Password must be 8 characters long').matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter'),
            phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter phone number"),
            invitationCode: yup.string(),
            termsAndConditions: yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
            contactAgree: yup.bool().oneOf([true], 'You need to accept mentioned conditions'),
        }),
        onSubmit: async (values) => {
            const payload = { ...values }
            try {
                const response = await heroSignup(payload);
                if (response.data.success) {
                    localStorage.setItem("token", response?.data?.data?.token);
                    navigate('/hero-signup-progress');
                }
            } catch (err) {
                console.log(err);
            }
        },
    });

    return (
        <div className="customer_signup sec_pd">
            <Image className="signup_img d-block d-lg-none w-100 mB30" src="assets/img/customer-sign-up.svg"></Image>
            <Container>
                <Row>
                    <Col sm={12} lg={7}>
                        <div className="sectionTitle pR50">
                            <h2 className="black poppins fz48 bold mB20 d-none d-lg-block"> Sign up today </h2>
                            <p className="black poppins fz20 regular">
                                Are you looking for on-demand personal security?
                                Do you want to feel safe on the streets of your city?
                                Join HERO the app based community on a mission to reduce crime rates in your city.
                                We put the power in your hands to feel safe and never walk alone.
                            </p>
                            <Image className="signup_img mT140 d-none d-lg-block w-100" src="assets/img/customer-sign-up.svg"></Image>
                        </div>
                    </Col>
                    <Col sm={12} lg={5}>
                        <p className="fz24 medium poppins black mt-1"> Get protected </p>
                        <Form>

                            <Form.Group className="mB10" controlId="fullName">
                                <Form.Control type="text" placeholder="Full Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.fullName && formik.errors.fullName ? (
                                    <small className="text-danger">{formik.errors.fullName}</small>
                                ) : null}
                            </Form.Group>

                            <Form.Group className="mB10" controlId="email">
                                <Form.Control type="email" placeholder="Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <small className="text-danger">{formik.errors.email}</small>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="mB10 iconinput" controlId="password">
                                <span onClick={() => setShowPassword(!showPassword)}>
                                    <svg class="eyeIcon" width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.9853 5.4944L20.5999 4.08476C15.3102 -1.36159 6.74595 -1.36159 1.45624 4.08476L0.0708443 5.4944C-0.0236148 5.59051 -0.0236148 5.78273 0.0708443 5.87884L1.58219 7.44867C6.77744 12.8309 15.2158 12.8309 20.411 7.44867L21.9224 5.87884C22.0798 5.78273 22.0798 5.62255 21.9853 5.4944ZM11.0596 9.94758C8.79256 9.94758 6.96635 8.05738 6.96635 5.71866C6.96635 3.37993 8.79256 1.48973 11.0596 1.48973C13.3266 1.48973 15.1528 3.37993 15.1528 5.71866C15.1528 8.05738 13.2951 9.94758 11.0596 9.94758Z" fill="black" />
                                        <path d="M13.4856 5.68641C13.4856 7.0663 12.4005 8.18532 11.0612 8.18532C9.72187 8.18532 8.63672 7.0663 8.63672 5.68641C8.63672 4.30652 9.72187 3.1875 11.0612 3.1875C12.4005 3.1875 13.4856 4.30652 13.4856 5.68641Z" fill="black" />
                                    </svg>
                                </span>
                                <Form.Control type={showPassword ? 'text' : 'password'} placeholder="Password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <small className="text-danger">{formik.errors.password}</small>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="mB10" controlId="phone">
                                <Form.Control placeholder="Phone Number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <small className="text-danger">{formik.errors.phone}</small>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="mB10" controlId="invitationCode">
                                <Form.Control
                                    type="text"
                                    placeholder="Invitation code (optional)"
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                            <Form>

                            </Form>
                            <div className="fz9 regular black poppins mT20">
                                <p className="d-flex mb-0">
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="terms&condition"
                                        className="me-2"
                                        onChange={formik.handleChange}
                                        name="termsAndConditions"
                                    />
                                    By proceeding, I agree to Hero's&nbsp;
                                    <NavLink to={'/customer-terms-conditions'} className="text-decoration-none"> Terms of Use </NavLink> &nbsp;and
                                    acknowledge that I have read the&nbsp;
                                    <NavLink to={'/customer-privacy-policy'} className="text-decoration-none">Privacy Policy.</NavLink>
                                </p>
                                {formik.errors.termsAndConditions ? (
                                    <small className="text-danger">{formik.errors.termsAndConditions}</small>
                                ) : null}
                                <p className="d-flex mT15 ">
                                    {['checkbox'].map((type) => (
                                        <Form.Check // prettier-ignore
                                            type={type}
                                            id={`default-${type}`}
                                            className="me-2"
                                            onChange={formik.handleChange}
                                            name="contactAgree"
                                        />
                                    ))}
                                    I also agree that Hero or its representatives may contact me by
                                    email, phone or SMS (including by automated means) at the email
                                    address or number I provide, including for marketing purposes.
                                </p>
                                {formik.errors.contactAgree ? (
                                    <small className="text-danger">{formik.errors.contactAgree}</small>
                                ) : null}
                            </div>

                            <div className="btnRow d-flex align-items-center mT40">
                                <Button className="btn btn-outline btn-outline-black me-3" onClick={formik.handleSubmit}>
                                    Sign Up
                                </Button>

                                {/* <div className="regular text-decoration-none fz16 poppins black signText">
                                    Already have an account?
                                    <Link to="/login" className="ms-2 text-decoration-none fz16 poppins black bold">
                                        Sign in
                                    </Link>
                                </div> */}

                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HeroSignup;
