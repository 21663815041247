import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getBlogs, getBlogDetail } from "../api/Blogs";

import { truncateHTMLString, getFormattedDate } from "../utils";

const CompanyBlog = () => {
  const [blogsData, setBlogsData] = useState([]);
  // const [blogData, setBlogData] = useState([]);
  const [baseURL, setBaseURL] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isMore, setIsMore] = useState(true);
  const [populateData, setPopulateData] = useState([]);
  // const [newFilterData, setNewFilterData] = useState([]);
  const [firstBlog, setFirstBlog] = useState({});
  const perPage = 9;

  useEffect(() => {
    getBlogs({
      page: currentPage,
      perPage,
    })
      .then((response) => {
        const blogList = response.data.data.blogList;
        setBlogsData((previous) => [
          ...previous,
          ...response.data.data.blogList,
        ]);

        setFirstBlog(response.data.data.blogList[0]);

        setBaseURL(response.data.data.s3Base);
        setIsMore(response.data.data.blogList.length === perPage);
        const popularBlogs = blogList.filter((blog) => blog.isPopular === true);
        setPopulateData(popularBlogs);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentPage]);

  return (
    <div className="companyBlog">
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand
              as={Link}
              to="/customer-future"
              className="bold poppins black"
            >
              Company
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero">
                  About us
                </Nav.Link>
                <Nav.Link as={Link} to="/how-it-works">
                  Our Team
                </Nav.Link>
                <Nav.Link as={Link} to="/newsroom">
                  News
                </Nav.Link>
                <Nav.Link as={Link} to="/blog">
                  Blog
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className="sec_pd">
        <Container>
          <div className="companyBlogTitle d-flex align-items-center mB40">
            <h4 className="black poppins fz40 bold text-center mb-0">
              {" "}
              Hero Blog{" "}
            </h4>
          </div>

          <Row>
            <Col sm={12} md={6} lg={8} className="d-none d-lg-block">
              <div className="newsBox newsBox_new">
                <div className="imageBox">
                  <img
                    src={`${baseURL}${firstBlog?.banner}`}
                    alt="NewsImage"
                    className="img-fluid"
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={4} className="d-none d-lg-block">
              <div className="newsBox newsBox_new">
                <div className="newsBox_content">
                  <span className="black fz16 medium poppins"> {firstBlog?.categories?.join(", ")} </span>
                  <p className="mb-0 black fz24 medium poppins mt-1">
                  <Link
                    to={firstBlog?.slug ? `/blog/${firstBlog?.slug}` : ""}
                    className="black text-decoration-none"
                  >
                    {firstBlog.heading}
                    </Link>
                  </p>
                  <div className="mb-0 black fz18 regular poppins mt-3">
                    {parse(truncateHTMLString(firstBlog?.description))}
                  </div>
                  <svg
                    className="rightIcon mt-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                  <span className="medium fz16 graya9 d-block mt-auto">
                    {getFormattedDate(firstBlog?.created)}
                  </span>
                </div>
              </div>
            </Col>

            {blogsData.slice(1, 4).map((blog, idx) => {
              return (
                <Col sm={12} md={6} lg={4}>
                  <div className="newsBox">
                    <div className="imageBox">
                      <img
                        src={`${baseURL}${blog?.banner}`}
                        alt="NewsImage"
                        className="img-fluid"
                      />
                    </div>
                    <Link
                      to={blog?.slug ? `/blog/${blog?.slug}` : ""}
                      className="black text-decoration-none"
                    >
                      <div className="newsBox_content">
                        <span className="black fz16 medium poppins">
                          {blog?.categories?.join(", ")}
                        </span>
                        <p className="mb-0 black fz24 medium poppins mt-1">
                        {blog.heading}
                        </p>
                        <span className="medium fz16 graya9 d-block mt-auto">
                        {getFormattedDate(blog?.created)}
                        </span>
                      </div>
                    </Link>
                  </div>
                </Col>
              );
            })}

            <Col sm={12} md={6} lg={4}>
              <div className="newsBox hfor_mobile">
                <div className="addBox">
                  <div className="addBox_title">
                    <p className="fz32 bold poppins white">
                      {" "}
                      Get Protection when you need it{" "}
                    </p>
                    <Link className="regular fz18 graya9">
                      {" "}
                      sign up for a safer journey{" "}
                    </Link>
                  </div>
                  <hr></hr>
                  <div className="addBox_title ">
                    <p className="fz32 bold poppins white">
                      {" "}
                      Get Protection when you need it{" "}
                    </p>
                    <Link className="regular fz18 graya9">
                      {" "}
                      sign up for a safer journey{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </Col>

            {blogsData.slice(4, 8).map((blog, idx) => {
              return (
                <Col sm={12} md={6} lg={4} key={idx}>
                  <div className="newsBox">
                    <div className="imageBox">
                      <img
                        src={`${baseURL}${blog?.banner}`}
                        alt="NewsImage"
                        className="img-fluid"
                      />
                    </div>
                    <Link
                      to={blog?.slug ? `/blog/${blog?.slug}` : ""}
                      className="black text-decoration-none"
                    >
                      <div className="newsBox_content">
                        <span className="black fz16 medium poppins">
                          {blog.heading}
                        </span>
                        <p className="mb-0 black fz24 medium poppins mt-1">
                          {parse(truncateHTMLString(blog?.description))}
                        </p>
                        <span className="medium fz16 graya9 d-block mt-auto">
                        {getFormattedDate(blog?.created)}
                        </span>
                      </div>
                    </Link>
                  </div>
                </Col>
              );
            })}

            <Col sm={12} md={6} lg={4}>
              <div className="mostPopular">
                <h4> Most Popular </h4>
                {populateData.slice(1, 4).map((item, id) => {
                  console.log("item", item);
                  return (
                    <div className="mostPopular_item d-flex justify-content-between mB30">
                      <div className="mostPopular_item_content" key={id}>
                        <span>
                          <Link
                            to={item?.slug ? `/blog/${item?.slug}` : ""}
                            className="black text-decoration-none"
                          >
                            {item.heading}
                          </Link>
                        </span>
                        <p>{parse(truncateHTMLString(item?.description))}</p>
                      </div>
                      <div className="mostPopular_img">
                        <img
                          src={`${baseURL}${item?.banner}`}
                          alt="NewsImage"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  );
                })}

                {/* <div className="mostPopular_item d-flex justify-content-between mB30">
                  <div className="mostPopular_item_content">
                    <span>Tips for Hero's, 20 Sept</span>
                    <p>Lorem Ipsum dolor sit amet lorem ipsum.</p>
                  </div>
                  <div className="mostPopular_img"></div>
                </div>
                <div className="mostPopular_item d-flex justify-content-between">
                  <div className="mostPopular_item_content">
                    <span>Tips for Hero's, 20 Sept</span>
                    <p>Lorem Ipsum dolor sit amet lorem ipsum.</p>
                  </div>
                  <div className="mostPopular_img"></div>
                </div> */}
              </div>
            </Col>
            {blogsData.slice(8, blogsData.length).map((blog, idx) => {
              return (
                <Col sm={12} md={6} lg={4} key={idx}>
                  <div className="newsBox">
                    <div className="imageBox">
                      <img
                        src={`${baseURL}${blog?.banner}`}
                        alt="NewsImage"
                        className="img-fluid"
                      />
                    </div>
                    <Link
                      to={blog?.slug ? `/blog/${blog?.slug}` : ""}
                      className="black text-decoration-none"
                    >
                      <div className="newsBox_content">
                        <span className="black fz16 medium poppins">
                          {blog.heading}
                        </span>
                        <p className="mb-0 black fz24 medium poppins mt-1">
                          {parse(truncateHTMLString(blog?.description))}
                        </p>
                        <span className="medium fz16 graya9 d-block mt-auto">
                        {getFormattedDate(blog?.created)}
                        </span>
                      </div>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CompanyBlog;
