import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const WhyWeNeed = () => {
  return (
    <div>
      <section className="beSafe sec_pd lesscrime">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12} md={12} lg={6}>
              <div className="sectionTitle">
                <h2 className="white poppins fz48 bold mb-3">
                  Crime prevention is everybody’s business
                </h2>
                <p className="mb-0 fz16 regular poppins white lh25">
                  Crime is on the rise throughout the United States.
                </p>
                <p className="mb-0 fz16 regular poppins white lh25">
                  In a recent poll, violent crime ranked as the third most
                  important issue for voters. With senseless acts of violence in
                  the train stations and on the streets of the Big Apple, it’s
                  time that changed.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="crimePicture sec_pd">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/crime-ic.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Crime statistics
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Crime statistics NYPD data showed a rapid increase in
                    murder, rape, robbery, felony assault, burglary, and grand
                    larceny auto in 2022. Felony assaults surpassed 22,000
                    incidents and murders neared 500 last year. What does this
                    mean for New Yorkers?
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read our blog
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/spot-crime.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Hot spots of crime
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    See where crime rates are highest in New York using the NYC
                    crime map. If crime in these areas are affecting you,
                    there’s something you can do about it.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read our blog
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/street-ic.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Types of crime on the streets
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    From petty theft, bag snatching and pickpocketing to
                    outright violence and mugging, there’s a dark side to NYC.
                    Find out more about protecting yourself and minimizing the
                    risk.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read our blog
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="howhero_help sec_pd">
        <Container>
          <div class="howhero_help_title fz40 bold poppins black mB70">
            How Hero can help
          </div>
          <Row className="align-items-center h-100">
            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/security-pic.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Why preventative security
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    Our mission is to attack crime before it occurs <br></br>by
                    providing affordable and safe protection services to{" "}
                    <br></br> our clients where and when they feel unsafe.{" "}
                    <br></br>It can take a seasoned criminal less than seven
                    seconds to <br></br>size you up. To decide whether you would
                    be easy to <br></br> rob, assault, kidnap, or whatever else
                    is on their mind.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Learn More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>

            <div className="mt-5"></div>
            <Col sm={12} md={12} lg={6} className="order2">
              <div className="whyHero_Wors_card p-0 shadow-none pR40 mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Always there when you need us
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    Our Heroes will serve as an ever growing force and <br></br>
                    visible presence on the streets and will be minutes<br></br>{" "}
                    away from being by your side to protect your journey.
                    <br></br> Read some of our success stories and positive
                    <br></br>
                    feedback from our clients.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Learn More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="order1">
              <figure>
                <Image src="assets/img/security-pic2.png"></Image>
              </figure>
            </Col>
            <div className="mt-5"></div>

            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/security-pic3.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Professionally trained so you <br></br> don’t have to be
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    Our Heroes have all signed up to our service with <br></br>
                    one goal in mind. To put their skills and training to{" "}
                    <br></br> use in serving the community and you. Holding a{" "}
                    <br></br>security license as a minimum, many of our Heroes{" "}
                    <br></br>
                    are trained in martial arts, advanced self-defense <br></br>{" "}
                    or are current military or ex-cops.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Learn More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserve one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>I am a Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default WhyWeNeed;
