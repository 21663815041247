import React from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomerFuture = () => {
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="/customer-future" className="bold poppins black">
              What is Hero?
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero">Overview</Nav.Link>
                <Nav.Link as={Link} to="/how-it-works">How It Works</Nav.Link>
                <Nav.Link as={Link} to="/fees">Fees</Nav.Link>
                <Nav.Link as={Link} to="/getstarted">GEt Started</Nav.Link>
                <Nav.Link as={Link} to="/customer-future" active>
                  The future of Hero
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-contact">Contact us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="safetyTechnology sec_pd">
        <Container>
          <Row className="align-items-center h-100 justify-content-end">
            <Col sm={12} lg={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz48 bold mB40">
                  Safety enabling<br></br> technology
                </h2>
                <p className="mb-0 fz24 medium poppins white lh36">
                  We are continually looking at ways to innovate within the
                  security space. Here area few things we have in mind for the
                  <br></br> future of Hero.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sec_pd bglightf3  futuresec_2">
        <Container>
          <Row className="align-items-center">
            <Col md={5} sm={12}>
              <div className="sectionTitle">
                <h2 className="black poppins fz36 bold mb-3">
                  HERO app <br></br> development
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  HERO app development Our app team are continually developing
                  new ways to protect our users; for example with maps that
                  serve as a safety landscape of the city, where people can
                  report crimes and dangerous areas to avoid.
                </p>

                <Link className="fz16 regular poppins black mT30 d-inline-block text-decoration-none">
                  Notify me of app updates{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </Link>
              </div>
            </Col>
            <Image
              className="mapView "
              src="./assets/img/hero-app-development.png"
            ></Image>
          </Row>
        </Container>
      </section>

      <section className="sec_pd futuresec_3 d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col md={5} sm={12} className="order2">
              <Image
                className="imgmobile  w-100"
                src="./assets/img/herocommunity.png"
              ></Image>
            </Col>
            <Col md={7} sm={12}>
              <div className="sectionTitle p-5">
                <h2 className="black poppins fz36 bold mb-3">HERO Community</h2>
                <p className="mb-0 fz16 regular poppins black lh25">
                  HERO Community Through a collective of like-minded people we
                  are aiming to create a community of users and security
                  professionals who can share information, tips and mutual
                  support to help everyone feel safer on the streets. Together
                  we can protect each other and make a difference!
                </p>

                <Link className="fz16 regular poppins black mT30 d-inline-block text-decoration-none">
                  Sign-up to our community
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserve one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>I am a Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default CustomerFuture;
